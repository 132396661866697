<template>
  <main class="main">
    <div class="container p404">
      <h1>Страница не готова</h1>
    </div>
  </main>
</template>

<script>
export default {
  name: "NotReady",
  asyncData({ res }) {
    if (res) {
      res.status(404);
    }
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
.p404 {
  margin-top var(--margin)
  margin-bottom var(--margin)
}
</style>
